import { Box, Grid, IconButton, makeStyles, Typography, createStyles } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AutocompleteDto,
  ContainerDtoContainerTypeEnum,
  ContainerDtoSemitrailerTypeEnum,
  ContainerDtoShippingContainerTypeEnum,
  TransitionDto,
} from '../../../api'
import { RHFAutocomplete } from '../../../components/shared/rhf/RHFAutocomplete'
import autocompleteService from '../../../services/AutocompleteService'
import { isAutocompleteItem } from '../../../utils/forms'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { ContainerForm } from '../../drivers/container/ContainerForm'
import { FormViewMode } from '../../../enums/FormViewMode'
import { KioskButtons } from '../KioskButtons'
import gateInService from '../../../services/GateInService'
import driverArrivalService from '../../../services/DriverArrivalService'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import transitionService from '../../../services/TransitionService'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationScheme } from '../../drivers/transition-form/ValidationScheme'
import { WrongIluDialog } from './WrongIluDialog'
import { GateInFormValues, initialValues } from './initialValues'
import { mapValues } from './mapper'

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      scale: 5,
      color: theme.palette.secondary.main,
      margin: '30px',
    },
    iconButton: {
      borderRadius: 12,
      minWidth: '150px',
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.8)',
      '&:hover, &.Mui-focusVisible, &:active': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 0',
      },
      transition: 'box-shadow 0.1s',
    },
    wrapper: {
      minHeight: '250px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      width: '100%',
    },
    typography: {
      textAlign: 'center',
      marginBottom: '30px',
      fontWeight: 650,
      fontSize: '24px',
    },
    buttonsWrapper: {
      display: 'flex',
      gap: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: '120px',
    },
    iconText: {
      fontWeight: 650,
      color: theme.palette.secondary.main,
    },
    header: {
      fontWeight: 650,
      color: theme.palette.primary.main,
      alignItems: 'center',
      marginTop: '20px',
    },
    grid: {
      maxWidth: '800px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '80px',
      minHeight: '400px',
    },
  })
)

interface KiosCheckIluProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskCheckIlu: FC<KiosCheckIluProps> = ({ idDriverArrival, previous, next }) => {
  const { driverArrival } = useActualDriverArival(idDriverArrival)

  const [options, setOptions] = useState<AutocompleteDto[]>([])
  const [transition, setTransition] = useState<TransitionDto | undefined>(undefined)
  const [cargo, setCargo] = useState<ContainerDtoContainerTypeEnum | undefined>(undefined)

  const [showDialog, setShowDialog] = useState(false)

  const formMethods = useForm<GateInFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(ValidationScheme(FormViewMode.KIOSK, transition?.container.containerType)),
  })
  const { t } = useTranslation()
  const classes = useStyles()

  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [inputValue, setInputValue] = useState('')

  const apiCalled = useRef<boolean>(false)

  const onChangeTextFieldInput = async (value: string) => {
    setInputValue(value)

    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CONTAINER')
      apiCalled.current = true
      setOptions(response.data)

      if (!response.data.length) {
        setTransition(undefined)
      }
    }, 500)
  }

  const handleOptionSelected = async (option: AutocompleteDto) => {
    try {
      const response = await gateInService.getPlannedTransitionForDriver(idDriverArrival ?? 0, option.id ?? 0)
      setTransition(response.data)
      formMethods.control.setValue('container.iluCode', option.name ?? '')
      formMethods.control.setValue('container.tarraWeight', response.data.container.tarraWeight)
      formMethods.control.setValue('container.semitrailerType', response.data.container.semitrailerType)
      formMethods.control.setValue('container.semitrailerLicencePlate', response.data.container.semitrailerLicencePlate)
      formMethods.control.setValue('container.shippingContainerType', response.data.container.shippingContainerType)
      formMethods.control.setValue('container.teu', response.data.container.teu)
      formMethods.control.setValue('container.shipowner.id', response.data.container.shipowner?.id)
      formMethods.control.setValue('container.containerType', response.data.container.containerType)
      formMethods.control.setValue('container.company.id', response.data.container.company?.id)
    } catch (err) {
      console.error('[handleOptionSelected] api call failed')
    }
  }

  const handleClickSemitrailer = () => {
    setCargo(ContainerDtoContainerTypeEnum.Semitrailer)
    setTransition({
      container: {
        iluCode: inputValue,
        teu: 1,
        containerType: ContainerDtoContainerTypeEnum.Semitrailer,
      },
    })
  }

  const handleClickContainer = () => {
    setCargo(ContainerDtoContainerTypeEnum.ShippingContainer)
    setTransition({
      container: {
        iluCode: inputValue,
        teu: 1,
        containerType: ContainerDtoContainerTypeEnum.ShippingContainer,
      },
    })
  }

  const onSubmit = async (formValues: GateInFormValues, skipIluValidation = false) => {
    const isSemitrailer = cargo === ContainerDtoContainerTypeEnum.Semitrailer

    // Validate ILU code if not skipping validation
    if (!skipIluValidation) {
      const iluCode = formValues.container.iluCode ?? inputValue
      const response = await transitionService.isValidIluCode(iluCode)
      if (!response.data) {
        setShowDialog(true)
        return
      }
    }

    if (transition?.id) {
      next()
      return
    }

    if (driverArrival?.plannedTransition?.container) {
      await transitionService.update(driverArrival.plannedTransition.id ?? 0, {
        ...driverArrival?.plannedTransition,
        container: {
          ...driverArrival?.plannedTransition?.container,
          iluCode: driverArrival?.plannedTransition?.container.iluCode ?? inputValue,
          tarraWeight: formValues.container.tarraWeight,
          containerType: driverArrival?.plannedTransition?.container.containerType as ContainerDtoContainerTypeEnum,
          teu: driverArrival?.plannedTransition?.container.teu ?? 0,
        },
      })
      next()
    } else {
      try {
        const newTransition: TransitionDto = {
          container: {
            iluCode: formValues.container.iluCode,
            company: {
              id: formValues.container.company.id,
              companyIdentificationNumber: '', //No idea where to get the data, Im not calling BE twenty times to get it so I can send it back
              companyTaxNumber: '',
              name: '',
            },
            containerType: cargo as ContainerDtoContainerTypeEnum,
            shipowner: !isSemitrailer
              ? {
                  id: formValues.container.shipowner.id,
                  name: '',
                }
              : undefined,
            tarraWeight: formValues.container.tarraWeight,
            teu: formValues.container.teu,
            shippingContainerType: formValues.container.shippingContainerType as ContainerDtoShippingContainerTypeEnum,
            semitrailerType: formValues.container.semitrailerType as ContainerDtoSemitrailerTypeEnum,
            semitrailerLicencePlate: formValues.container.semitrailerLicencePlate,
          },
        }
        await gateInService.createByDriver(idDriverArrival ?? 0, newTransition)
        next()
      } catch (err) {
        console.error(err)
      }
    }
  }

  const onClickBack = async () => {
    try {
      await driverArrivalService.deleteGateInOrOut(idDriverArrival ?? 0, 'IN')
    } catch (error) {
      console.error('Error deleting gatein/gateout', error)
    }
    previous()
  }

  const handleClickContinue = () => {
    onSubmit(formMethods.getValues(), true)
  }

  useEffect(() => {
    if (driverArrival?.plannedTransition?.container) {
      formMethods.control.setValue('container.iluCode', driverArrival.plannedTransition?.container.iluCode)
      formMethods.control.setValue('container.containerType', driverArrival.plannedTransition?.container.containerType)
      formMethods.control.setValue(
        'container.shippingContainerType',
        driverArrival.plannedTransition?.container.shippingContainerType
      )
      formMethods.control.setValue(
        'container.semitrailerType',
        driverArrival.plannedTransition?.container.semitrailerType
      )
      formMethods.control.setValue(
        'container.semitrailerLicencePlate',
        driverArrival.plannedTransition?.container.semitrailerLicencePlate
      )
      formMethods.control.setValue('container.tarraWeight', driverArrival.plannedTransition?.container.tarraWeight)
      formMethods.control.setValue('container.teu', driverArrival.plannedTransition?.container.teu)
      formMethods.control.setValue('container.shipowner.id', driverArrival.plannedTransition?.container.shipowner?.id)
      formMethods.control.setValue('container.company.id', driverArrival.plannedTransition?.container.company?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [driverArrival?.plannedTransition?.container])

  useFormInitialValues(mapValues(transition?.container), formMethods.reset)

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Box className={classes.grid}>
            <Typography variant="h5" className={classes.header}>
              {t('kioskApp.step2.ilucode')}
            </Typography>
            <RHFAutocomplete
              options={options.map((o) => ({ id: o.id ?? 0, name: o.name ?? '' }))}
              label=""
              name="container.iluCode"
              onTextInputChange={onChangeTextFieldInput}
              placeholder={t('kioskApp.step2.placeholderIluCode')}
              onChange={(autocompleteData) => {
                if (isAutocompleteItem(autocompleteData)) {
                  handleOptionSelected({
                    id: autocompleteData.id,
                    name: autocompleteData.name,
                  })
                }
              }}
            />

            {apiCalled.current && options.length === 0 && (
              <Box className={classes.wrapper}>
                <Typography className={classes.typography}>{t('kioskApp.step2.selectCargoType')}</Typography>
                <Box className={classes.buttonsWrapper}>
                  <IconButton className={classes.iconButton} onClick={handleClickSemitrailer}>
                    <Grid container direction="column">
                      <Grid item>
                        <SemitrailerIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.iconText}>{t('kioskApp.step2.type.semitrailer')}</Typography>
                      </Grid>
                    </Grid>
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={handleClickContainer}>
                    <Grid container direction="column">
                      <Grid item>
                        <ContainerIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.iconText}>
                          {t('kioskApp.step2.type.shippingContainer')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </IconButton>
                </Box>
              </Box>
            )}

            {(transition || driverArrival?.plannedTransition?.container) && (
              <ContainerForm
                key={transition?.container.containerType}
                container={transition?.container || driverArrival?.plannedTransition?.container}
                formMode={FormViewMode.KIOSK}
              />
            )}
          </Box>
          <KioskButtons
            visibleBack
            visibleNext={!!transition || !!driverArrival?.plannedTransition?.container}
            onClickBack={onClickBack}
          />
        </form>
      </FormProvider>
      {showDialog && (
        <WrongIluDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          iluCode={inputValue}
          onContinue={handleClickContinue}
        />
      )}
    </>
  )
}
